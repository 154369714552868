import React from "react";
import chevron from "../img/chevron.png";
import "./circle-icon.sass";
import PropTypes from "prop-types";

const CircleArrow = ({ onClick, direction, disabled }) => {

  return (
    <div className={`circle-arrow ${direction} ${disabled && ' disabled'}`} onClick={onClick}>
      <img src={chevron} className="icon" alt="arrow" />
    </div>
  );
}

CircleArrow.propTypes = {
  onClick: PropTypes.func,
  direction: PropTypes.string,
}


export default CircleArrow;