import React from "react";
import PropTypes from "prop-types";
import CircleArrow from "./CircleArrow";
import "./redirect-header.sass";
import { Link } from "gatsby";

const RedirectHeader = ({ onClick, title, route, showRedirect = true }) => {
  return (
    <div className="redirect-header" onClick={onClick}>
      <h1>{title}</h1>
      {showRedirect && (
        <div className="arrow-container">
          <h6>Zobacz wszystkie</h6>
          {
            route ?
              (
                <Link to={route}>
                  <CircleArrow direction="right" />
                </Link>
              ) :
              <CircleArrow onClick={onClick} direction="right" />
          }

        </div>
      )
      }
    </div>
  );
}

RedirectHeader.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
  route: PropTypes.string,
}

export default RedirectHeader;