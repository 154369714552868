import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import { GatsbyImage } from "gatsby-plugin-image";
import RedirectHeader from "../components/RedirectHeader";
import Button from "../components/Button";
import "./about.sass"
import { SEO } from "../components/SEO";

const AboutPageTemplate = ({ pageContext, widgets }) => {
  return (
    <div className="section-container">
      <section className="section">
        <RedirectHeader title="O nas" route="/" showRedirect={false} />
        <div className="container">
          <div className="content">
            {widgets.map((widget, index) => {
              const { title, description, image, hoverImage, link } = widget;
              return (
                <div className="about-widget" key={index}>
                  <div className="about-widget-text">
                    <h3 className="h5-black">{title}</h3>
                    <p>{description.length > 163 ? description.substring(0, 163) + " [...]" : description}</p>
                    <Link to={link}>
                      <Button text="Zobacz więcej" type="secondary" />
                    </Link>
                  </div>
                  <div className="about-widget-image">
                    <GatsbyImage image={image.childImageSharp.gatsbyImageData} alt={title} />
                  </div>

                </div>
              )
            })}
          </div>
        </div>
      </section>
    </div>
  );
}

AboutPageTemplate.propTypes = {
  pageContext: PropTypes.object,
  widgets: PropTypes.array,
};

const AboutPage = ({ data, pageContext }) => {
  const { widget } = data.markdownRemark.frontmatter;
  return (
    <Layout>
      <AboutPageTemplate pageContext={pageContext} widgets={widget} />
    </Layout>

  );
}

AboutPage.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
};

export default AboutPage;
export const Head = () => (
  <SEO
    title="O nas"
    description="O nas"
    path="/about"
  />
)

export const pageQuery = graphql`
  query AboutPageTemplate {
    markdownRemark(frontmatter: {templateKey: {eq: "about-page"}}) {
      frontmatter {
        title
        widget {
          description
          title
          link
          image {
            childImageSharp {
              gatsbyImageData(quality: 100,  placeholder: BLURRED)
            }
          }
          hoverImage {
            childImageSharp {
              gatsbyImageData(quality: 100,  placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`;
