import React from "react";
import PropTypes from "prop-types";
import "./button.sass";

const Button = ({ text, onClick, type = 'primary' }) => {
  return (
    <button className={`${type === 'primary' ? 'btn-primary' : 'btn-secondary'} `} onClick={onClick}>{text}</button>
  )
}

export default Button;